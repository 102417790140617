import React from "react";
import {Dayjs} from "dayjs";
import FormControl from "@material-ui/core/FormControl";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {TextField} from "@material-ui/core";

export const DateInput = (data: any) => {
    const [value, setValue] = React.useState<Dayjs | null>(null);
    const { onChange, name, error, maxDate, defaultValue, ...rest } = data;

    const changeHandler = (newValue: any) => {
        setValue(newValue);
        if (newValue) {
            onChange(newValue)
        }
    };
    return (
        <FormControl fullWidth required>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    inputFormat="DD/MM/YYYY"
                    renderInput={(props: any) => <TextField {...props} error={error} name={name} margin="dense" />}
                    value={value ? value : defaultValue}
                    onChange={changeHandler}
                    maxDate={maxDate}
                    {...rest}
                />
            </LocalizationProvider>
        </FormControl>
    )
}