import React from 'react';
import 'dayjs/locale/en-gb';
import {BoolDictionary, StringDictionary} from "../custom_types/customTypes";
import ConfirmDialog from "./ConfirmDialog";
import {AmountInput} from "../form_inputs/AmountInput";
import {PaidAtInput} from "../form_inputs/PaidAtInput";

const PostTransactionDialog = (props: { id: string; icon: any; button_type: "list" | "show";
    fields: any, type: string }) => {

    const dialogTitleMap: StringDictionary = {
        "REPAYMENT": "Post Repayment",
        "INTEREST_WAIVER": "Post Interest Waiver",
        "REFUND": "Post Refund",
        "PRINCIPAL_WRITEOFF": "Post Write-Off",
        "DISBURSMENT": "Disburse"
    }

    const DialogField = (props: { fieldKey: string, onChange: any, error: boolean }) => {
        if (props.fieldKey === "amount") {
            return (<AmountInput {...props} />)
        }
        return (<PaidAtInput {...props} />)
    }

    const getParams = (id: any, values: any, props: any) => {
        return {
            data: {
                amount: values['amount'],
                paid_at: values['paid_at'],
                transaction_type: props.type
            }
        }
    }

    const getFormFields = (fields: any, getOnChange: any, errors: BoolDictionary) => {
        return fields.map((fieldKey: string) => (
            <DialogField fieldKey={fieldKey} onChange={getOnChange(fieldKey)} error={errors[fieldKey]} key={fieldKey} />
        ));
    }

    return (<ConfirmDialog {...props}
                           getParams={getParams}
                           getFormFields={getFormFields}
                           data_provider_endpoint={"vault/loanaccount/transactions/" + props.id}
                           data_provider_call_type="create"
                           label={dialogTitleMap[props.type] + ''}
                           formValidationAlert="Please enter amount and date/time"
                           requestErrorMessage="An unexpected error occurred when posting transaction. Please report this issue to engineering."
                           redirectTo={'/vault/loan/loans/' + props.id + '/show/expected_schedule/*'}
        />
    )
}

export default PostTransactionDialog