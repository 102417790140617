import React from 'react';
import 'dayjs/locale/en-gb';
import {EffectiveDateInput} from "../form_inputs/EffectiveDateInput";
import {RegularPaymentDayInput} from "../form_inputs/RegularPaymentDayInput";
import ConfirmDialog from "./ConfirmDialog";
import {BoolDictionary} from "../custom_types/customTypes";
import {ContractedInput} from "../form_inputs/ContractedInput";

const AddDDChangeDialog = (props: { id: string; icon: any; button_type: "list" | "show";
    fields: any }) => {
    const DialogField = (props: { fieldKey: string, onChange: any, error: boolean }) => {
        if (props.fieldKey === "regular_payment_day") {
            return (<RegularPaymentDayInput {...props} />)
        }
        if (props.fieldKey === "effective_date") {
            return (<EffectiveDateInput {...props} />)
        }
        return (<ContractedInput {...props} />)
    }

    const getParams = (id: any, values: any) => {
        return {
            data: {
                loan: id,
                regular_payment_day: values['regular_payment_day'],
                effective_date: values['effective_date'],
                contracted: values["contracted"] === null ? false : values["contracted"],
                purpose: "DD_CHANGE"
            }
        }
    }

    const getFormFields = (fields: any, getOnChange: any, errors: BoolDictionary) => {
        return fields.map((fieldKey: string) => (
            <DialogField fieldKey={fieldKey} onChange={getOnChange(fieldKey)} error={errors[fieldKey]} key={fieldKey} />
        ));
    }

    return (<ConfirmDialog {...props}
                           getParams={getParams}
                           getFormFields={getFormFields}
                           data_provider_endpoint="vault/loan/mutation/"
                           data_provider_call_type="create"
                           label="Add Direct Debit Change"
                           formValidationAlert="Please enter regular payment day and effective date"
                           requestErrorMessage="An unexpected error occurred when adding direct debit change. Please report this issue to engineering."
                           redirectTo={'/vault/loan/loans/' + props.id + '/show/expected_schedule/*'}
        />
    )
}

export default AddDDChangeDialog